<template>
    <v-tabs
        v-model="tab"
        class="mt-2 mb-3"
        :right="isRight"
    >
        <v-tab v-for="tab in elements" :key="tab">{{ tab.text }}</v-tab>
    </v-tabs>
</template>

<script>
export default {
    name: "TabNav",
    props: ["elements", "position"],
    mounted() {
        for (let i = 0; i < this.elements.length; i++) {
            if (this.elements[i].active) {
                this.tab = i;
            }
        }
    },
    computed: {
        isRight() {
            return this.position !== "left";
        }
    },
    data() {
        return {
            tab: 0
        };
    },
    watch: {
        tab(newValue) {
            for (let i = 0; i < this.elements.length; i++) {
                if (newValue === i && !this.elements[i].active) {
                    this.$router.push(this.elements[i].to);
                }
            }
        }
    }
};
</script>

<style scoped>

</style>